// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-registry-item-js": () => import("/opt/build/repo/src/templates/registry-item.js" /* webpackChunkName: "component---src-templates-registry-item-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lodging-js": () => import("/opt/build/repo/src/pages/lodging.js" /* webpackChunkName: "component---src-pages-lodging-js" */),
  "component---src-pages-registry-thanks-js": () => import("/opt/build/repo/src/pages/registry-thanks.js" /* webpackChunkName: "component---src-pages-registry-thanks-js" */),
  "component---src-pages-registry-js": () => import("/opt/build/repo/src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-rsvp-js": () => import("/opt/build/repo/src/pages/rsvp.js" /* webpackChunkName: "component---src-pages-rsvp-js" */),
  "component---src-pages-things-to-do-old-2-js": () => import("/opt/build/repo/src/pages/things-to-do-old-2.js" /* webpackChunkName: "component---src-pages-things-to-do-old-2-js" */),
  "component---src-pages-things-to-do-old-js": () => import("/opt/build/repo/src/pages/things-to-do-old.js" /* webpackChunkName: "component---src-pages-things-to-do-old-js" */),
  "component---src-pages-things-to-do-js": () => import("/opt/build/repo/src/pages/things-to-do.js" /* webpackChunkName: "component---src-pages-things-to-do-js" */),
  "component---src-pages-wedding-js": () => import("/opt/build/repo/src/pages/wedding.js" /* webpackChunkName: "component---src-pages-wedding-js" */)
}

